<template>
  <div>
    <header itemscope itemtype="http://schema.org/LocalBusiness">
      <div class="logo-placeholder">
        <img
          rel="preload"
          itemprop="image"
          lang="es"
          alt="Reus Autoescuela Prim, tu autoescuela en Reus. Mejores precios y atención al cliente. Sin competencia."
          src="img/logo.png"
        />
        <h4 class="logo-title" itemprop="name">Autoescola Prim Reus</h4>
      </div>
      <div class="contact">
        <p
          itemprop="address"
          itemscope
          itemtype="http://schema.org/PostalAddress"
        >
          <span itemprop="streetAddress">Avda. Prat de la Riba 12</span> (
          <span itemprop="postalCode">43201</span>
          <span itemprop="addressLocality"> Reus</span> )
        </p>
        <p itemprop="email">autoescolaprim@gmail.com</p>
        <p itemprop="telephone">Tlf. 977 318 907</p>
        <!-- <div
          class="fb-like"
          data-href="https://www.facebook.com/autoescolaprim"
          data-layout="button_count"
          data-action="recommend"
          data-size="small"
          data-show-faces="false"
          data-share="false"
        ></div> -->
      </div>

      <div class="info-links">
        <a href="https://www.ponsgo.com">Ponsgo.com</a>
        <a
          href="https://sedeapl.dgt.gob.es/WEB_EXAM_AUTO/service/TiposExamenesServlet"
          >Test DGT</a
        >
        <a
          href="https://sedeapl.dgt.gob.es:7443/WEB_NOTP_CONSULTA/consultaNota.faces"
          >Notes examen</a
        >
      </div>
    </header>

    <HolidaySign v-if="false" date="27 d'agost" />

    <div class="twitter-notifications">
      <a
        class="twitter-timeline"
        href="https://twitter.com/autoescolaprim"
        data-chrome="noheader,nofooter,noborders"
        data-tweet-limit="1"
        data-show-replies="false"
      ></a>
    </div>

    <div class="offers">
      <div class="offer" itemscope itemtype="http://schema.org/Product">
        <span itemprop="name">Els millors preus, sense competència</span>
      </div>
    </div>

    <div class="features">
      <div class="feature">
        <em class="glyphicon glyphicon-road"></em>
        <p>
          Facilitem l'obtenció del permís
        </p>
        <p>
          <span class="underline">B</span>
        </p>
      </div>
      <div class="feature">
        <em class="glyphicon glyphicon-user"></em>
        <p>Ordinadors amb pantalla tàctil per fer tests a l'autoescola.</p>
      </div>
      <div class="feature">
        <em class="glyphicon glyphicon-home"></em>
        <p>TEÒRICA ONLINE 24 HORES</p>
      </div>
    </div>

    <div class="classes">
      <h4>Aula Online</h4>
      <p>
        Accedeix a les classes de teòrica a través d'internet, repassa els
        continguts desde casa.
      </p>
      <div class="classes-links">
        <a target="_blank" href="https://vimeo.com/173584440">Accidents</a>
        <a target="_blank" href="https://vimeo.com/159462891">Advertir</a>
        <a target="_blank" href="https://vimeo.com/159244589">Avançament</a>
        <a target="_blank" href="https://vimeo.com/173806727">Conducció</a>
        <a target="_blank" href="https://vimeo.com/159461831">Carrils</a>
        <a target="_blank" href="https://vimeo.com/158618829">Distàncies</a>
        <a target="_blank" href="https://vimeo.com/159244595">Incorporació</a>
        <a target="_blank" href="https://vimeo.com/159461832">Llums</a>
        <a target="_blank" href="https://vimeo.com/173816494">Marques Vials</a>
        <a target="_blank" href="https://vimeo.com/159244598">Parada</a>
        <a target="_blank" href="https://vimeo.com/159244592">Posició</a>
        <a target="_blank" href="https://vimeo.com/173815196">Pneumàtics</a>
        <a target="_blank" href="https://vimeo.com/159461833">Prioritats</a>
        <a target="_blank" href="https://vimeo.com/159244603">Reacció</a>
        <a target="_blank" href="https://vimeo.com/173819056">Semàfors</a>
        <a target="_blank" href="https://vimeo.com/173620133">Seguretat Vial</a>
        <a target="_blank" href="https://vimeo.com/159244609">Velocitats</a>
        <a target="_blank" href="https://vimeo.com/159345000"
          >Senyals Verticals</a
        >
        <a target="_blank" href="https://vimeo.com/173584317">Resum 1</a>
      </div>
    </div>
    <div class="maps">
      <h4>Ubicació</h4>
      <iframe
        title="Entrada Carrer dels Recs"
        class="map"
        src="https://www.google.com/maps/embed?pb=!4v1647166533575!6m8!1m7!1spLMpIcfDq4fn5hdQ9tC5Xw!2m2!1d41.15717903671067!2d1.105203144742093!3f265.91!4f-7.340000000000003!5f0.5970117501821992"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
      <iframe
        title="Entrada Prat de la Riba"
        class="map"
        src="https://www.google.com/maps/embed?pb=!4v1647166505682!6m8!1m7!1soIO8kW2952O8N9DY71pfDw!2m2!1d41.15758786979121!2d1.105024340819978!3f230.17!4f-12.569999999999993!5f0.5970117501821992"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<script>
import HolidaySign from "./HolidaysSign.vue";

export default {
  components: {
    HolidaySign,
  },
};
</script>
