<template>
  <MainWebsite />
</template>

<script>
import MainWebsite from "./components/MainWebsite.vue";

export default {
  components: {
    MainWebsite,
  },
};
</script>
