<template>
  <div class="holidays">
    <em class="glyphicon glyphicon-info-sign"></em>
    Tancat per vacances fins el {{ date }}
    <em class="glyphicon glyphicon-info-sign"></em>
  </div>
</template>

<script>
export default {
  props: {
    date: String,
  },
};
</script>
